import { api, API } from '@/api';
import router from '../router';
import { LANGUAGES } from '../utils/constants';

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem('user') || {},
    resetPasswordPending: false,
    resetPasswordResult: {},
    isLoggedIn: !!localStorage.getItem('token'),
    token: localStorage.getItem('token') || '',
    registerUserPending: false,
    messagesPending: false,
    language: localStorage.getItem('user')?.language || LANGUAGES.EN
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getLoader(state) {
      return state.resetPasswordPending;
    },
    getResetResult(state) {
      return state.resetPasswordResult;
    },
    getIsLoggedIn(state) {
      return state.isLoggedIn;
    },
    getRegisterLoader(state) {
      return state.registerUserPending;
    },
    getUserLanguage(state) {
      return state.language;
    }
  },
  mutations: {
    SET_USER(state, { user, token }) {
      state.user = user;
      state.token = token;
      state.isLoggedIn = true;
    },
    SET_RESET_PENDING(state, payload) {
      state.resetPasswordPending = payload;
    },
    SET_RESET_RESULT(state, payload) {
      state.resetPasswordResult = payload;
      state.resetPasswordPending = false;
    },
    SET_USER_LOGOUT(state) {
      state.isLoggedIn = false;
      state.token = '';
      state.user = {};
    },
    USER_REGISTER_PENDING(state, payload) {
      state.registerUserPending = payload;
    },
    GET_MESSAGES_PENDING(state, payload) {
      state.registerUserPending = payload;
    },
    SET_USER_LANGUAGE(state, payload) {
      state.language = payload;
    }
  },
  actions: {
    async logIn({ commit }, payload) {
      try {
        const response = await api.post(API.USER_LOGIN, payload);
        const user = response.data.message;
        const token = response.data.token;
        const messagesList = response.data['List of errors for user'];
        const language = response.data.message.language || LANGUAGES.EN;
        commit('SET_USER', { user, token });
        commit('SET_USER_LANGUAGE', language);

        localStorage.setItem('messagesList', JSON.stringify(messagesList));
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
      } catch (error) {
        console.log('>>>', error, ' POST error  <<<');
        throw new Error(error.response ? error.response.data.message : error);
      }
    },
    async resetPassword({ commit }, payload) {
      commit('SET_RESET_PENDING', true);
      try {
        const result = await api.post(API.USER_RESET, payload);
        commit('SET_RESET_RESULT', result.data);
      } catch (error) {
        console.log('>>>', error, ' POST error  <<<');
        commit('SET_RESET_RESULT', error.response.data);
        throw new Error(error);
      }
    },
    async setNewPassword({ commit }, { payload, token }) {
      commit('SET_RESET_PENDING', true);
      try {
        const response = await api.post(API.USER_CHANGEPASS, payload, {
          headers: { Authorization: 'Bearer ' + token }
        });
        commit('SET_RESET_PENDING', false);
        return response.data.message;
      } catch (error) {
        console.log('>>>', error, ' POST error  <<<');
        commit('SET_RESET_PENDING', false);
        throw new Error(error);
      }
    },
    async logOut({ commit }) {
      try {
        await api.post(API.USER_LOGOUT);
        commit('SET_USER_LOGOUT');
        // localStorage.clear();
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        router.push('/');
      } catch (error) {
        console.log('>>>', error, ' logOut error  <<<');
      }
    },
    async registerUser({ commit }, payload) {
      commit('USER_REGISTER_PENDING', true);
      try {
        const response = await api.post(API.USER_REGISTER, payload);
        console.log(response);
        commit('USER_REGISTER_PENDING', false);
        return response.data.message;
      } catch (error) {
        console.log('>>>', error, ' POST error  <<<');
        commit('USER_REGISTER_PENDING', false);
        throw new Error(error.response ? error.response.data.message : error);
      }
    },
    async getLanguage({ commit }, payload) {
      commit('GET_MESSAGES_PENDING', true);
      try {
        const messagesList = await api.get(`${API.TRANSLATE}/${payload}`);
        localStorage.setItem(
          'messagesList',
          JSON.stringify(messagesList.data['List of errors'])
        );
        console.log(payload);
        commit('SET_USER_LANGUAGE', payload);
      } catch (error) {
        console.log('>>>', error, ' GET error  <<<');
        throw new Error(error);
      } finally {
        commit('GET_MESSAGES_PENDING', false);
      }
    }
  }
};
