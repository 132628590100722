import { api, API } from '@/api';

export default {
  namespaced: true,
  state: {
    loading: false,
    selectedData: {}
  },
  getters: {
    getSelectedData(state) {
      return state.selectedData;
    }
  },
  mutations: {
    LOAD_PORTS_LIST(state, payload) {
      state.loading = payload;
    },
    SET_SELECTED_DATA(state, payload) {
      state.selectedData = payload;
    }
  },
  actions: {
    async loadPortsList({ commit }) {
      commit('LOAD_PORTS_LIST', true);
      try {
        const portsList = await api.get(API.AVAILABLE_PORTS);

        commit('LOAD_PORTS_LIST', false);
        return portsList.data.payload;
      } catch (error) {
        console.log('>>>', error, ' GET error  <<<');
        throw new Error(error.response ? error.response.data.message : error);
      }
    },
    saveSelectedData({ commit }, payload) {
      commit('SET_SELECTED_DATA', payload);
    },
    async loadContainerList({ commit }) {
      commit('LOAD_PORTS_LIST', true);
      try {
        const containerList = await api.get(API.CONTAINER);

        commit('LOAD_PORTS_LIST', false);

        return containerList.data.payload;
      } catch (error) {
        console.log('>>>', error, ' GET error  <<<');
      }
    }
  }
};
