<template>
  <v-container>
    <v-btn to="/" class="px-2"
      ><v-icon>mdi-chevron-left</v-icon>Go to Home page</v-btn
    >

    <h1 class="display-1 font-weight-bold mb-5 text-center">
      Register
    </h1>

    <v-form
      ref="form"
      v-model="validation.valid"
      lazy-validation
      :disabled="getRegisterLoader"
    >
      <v-card class="pa-3 mb-12" color="#f0f0f0">
        <v-row class="text-left">
          <v-col cols="6">
            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Register as*</p>
              </v-col>
              <v-col cols="10">
                <v-select
                  :items="roles"
                  outlined
                  background-color="white"
                  v-model="register.type"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-3 mb-12" color="#f0f0f0">
        <v-card color="#dddddd">
          <v-card-title>My Personal Information</v-card-title>
        </v-card>

        <v-row class="text-left">
          <v-col cols="6">
            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">First Name*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.first_name"
                  :rules="validation.firstNameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Last Name*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.last_name"
                  :rules="validation.lastameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Phone</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.phone_number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Avatar</p>
              </v-col>
              <v-col cols="10">
                <v-file-input
                  :rules="validation.imgSize"
                  :error-messages="imageError"
                  accept=" image/jpeg, image/png, image/gif"
                  placeholder="File input"
                  prepend-inner-icon="mdi-camera"
                  prepend-icon=""
                  outlined
                  background-color="white"
                  v-model="register.file"
                  @change="uploadImage"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row v-if="register.type === 'Carrier'">
              <v-col cols="2">
                <p class="ml-3 pt-4">Job Title</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.job_title"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="register.type === 'Carrier'"
        class="pa-3 mb-12"
        color="#f0f0f0"
      >
        <v-card color="#dddddd">
          <v-card-title>My Company Details</v-card-title>
        </v-card>

        <v-row class="text-left">
          <v-col cols="6">
            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Company*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.company_name"
                  :rules="validation.companyRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Street*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.street"
                  :rules="validation.streetRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Postal Code</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.postal_code"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">City*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.city"
                  :rules="validation.cityRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Country, Region*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.country"
                  :rules="validation.countryRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Website</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  background-color="white"
                  v-model="register.website"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="pa-3 mb-12" color="#f0f0f0">
        <v-card color="#dddddd">
          <v-card-title>My Account</v-card-title>
        </v-card>

        <v-row class="text-left">
          <v-col cols="6">
            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">E-mail*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  type="email"
                  background-color="white"
                  v-model="register.email"
                  :rules="validation.emailRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Password*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  type="password"
                  background-color="white"
                  v-model="register.password"
                  :rules="validation.passwordRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <p class="ml-3 pt-4">Repeat Password*</p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  type="password"
                  background-color="white"
                  v-model="repeatPassword"
                  :rules="passwordConfirmationRule"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="pt-16" cols="6">
            <p class="pt-16 mt-5">
              (At least 8 characters, containing one letter and one digit.
              Allowed special characters: #, $, @)
            </p>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="pa-3 mb-12" color="#f0f0f0">
        <v-card color="#dddddd">
          <v-card-title>Special Authorization</v-card-title>
        </v-card>
        <div class="pt-3">
          <p>
            Additionally, I can apply for special features, such as E-Invoice
            and Sea Waybill Download.<br />
            These features commonly require a few days processing time for
            adequate verification and set-up.
          </p>
          <p>
            <span class="font-weight-bold">E-Invoice Download</span>
            authorization always applies
            <span class="font-weight-bold">for your entire company.</span> It
            cannot be set up on basis of 'single user' only.
          </p>
        </div>

        <v-row class="font-weight-bold">
          <v-col cols="1"> </v-col>
          <v-col cols="9">
            Online Function
          </v-col>
          <v-col cols="2">
            Granted
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="1" class="text-center">
            <v-checkbox
              class="mt-0 pt-0"
              v-model="register.invoice"
              color="#1f1f1f"
            ></v-checkbox>
          </v-col>
          <v-col cols="9">
            E-INVOICE DOWNLOAD
          </v-col>
          <v-col cols="2">
            N
          </v-col>
        </v-row>

        <div class="pt-3">
          <p>
            Sea Waybill Download
            <span class="font-weight-bold">E-Invoice Download</span> requires
            authorized contract signatures<span class="font-weight-bold"
              >E-Invoice Download</span
            >
            of both parties upon first usage.<br />
            View and
            <a
              href="https://www.hapag-lloyd.com/en/online-business/documents/sea-waybill-terms-and-conditions.html"
              class=" link orange-text"
              >sign the contract for Sea Waybill Download</a
            >.<br />
            It is <span class="font-weight-bold">E-Invoice Download</span> not
            available for Import Customers.
          </p>
        </div>

        <v-row class="font-weight-bold">
          <v-col cols="1"> </v-col>
          <v-col cols="9">
            Online Function
          </v-col>
          <v-col cols="2">
            Granted
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="1" class="text-center">
            <v-checkbox
              class="mt-0 pt-0"
              v-model="register.seaWaybill"
              color="#1f1f1f"
            ></v-checkbox>
          </v-col>
          <v-col cols="9">
            SEA WAYBILL DOWNLOAD
          </v-col>
          <v-col cols="2">
            N
          </v-col>
        </v-row>
      </v-card>

      <v-col cols="12">
        <v-row>
          <v-checkbox
            class="mt-0 pt-0"
            v-model="isTermsAgree"
            color="#1f1f1f"
            :rules="validation.isTermsAgreeRules"
            required
          ></v-checkbox>

          <p>
            I have read and agree to the
            <a
              href="https://www.hapag-lloyd.com/en/meta/privacy-statement.html"
              class="link yellow-text"
              >Privacy Terms</a
            >
            and
            <a
              href="https://www.hapag-lloyd.com/en/meta/legal-terms.html"
              class="link yellow-text"
              >Terms of Use</a
            >
            of the website. (Mandatory)
          </p>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-btn
            large
            color="#f6662d"
            @click="onSumbitRegister"
            :loading="getRegisterLoader"
          >
            <div class="white--text">Sumbit</div>
          </v-btn>
        </v-row>
      </v-col>
    </v-form>
  </v-container>
</template>

<script>
import { emailRegEx, passwordRegEx } from '@/utils/regEx';
import { mapActions, mapGetters } from 'vuex';
import { TOAST_TYPE, USER_ROLES, AVATAR_IMG } from '../utils/constants';
export default {
  name: 'Register',

  data: () => ({
    register: {
      first_name: '',
      last_name: '',
      phone_number: '',
      company_name: '',
      street: '',
      postal_code: '',
      city: '',
      country: '',
      email: '',
      password: '',
      invoice: false,
      seaWaybill: false,
      type: 'Carrier',
      file: null,
      job_title: ''
    },
    imageError: '',
    repeatPassword: '',
    isTermsAgree: false,
    validation: {
      valid: false,
      firstNameRules: [(v) => !!v || 'Required'],
      lastNameRules: [(v) => !!v || 'Required'],
      companyRules: [(v) => !!v || 'Required'],
      streetRules: [(v) => !!v || 'Required'],
      sityRules: [(v) => !!v || 'Required'],
      countryRules: [(v) => !!v || 'Required'],
      emailRules: [
        (v) => !!v || 'Required',
        (v) => {
          return emailRegEx.test(v) || 'E-mail must be valid';
        }
      ],
      passwordRules: [
        (v) => !!v || 'Required',
        (v) => {
          return passwordRegEx.test(v) || 'Password must be valid';
        }
      ],
      isTermsAgreeRules: [(v) => !!v || 'Required'],
      imgSize: [
        (v) =>
          !v ||
          v.size < AVATAR_IMG.SIZE ||
          `Avatar size should be less than ${AVATAR_IMG.SIZE / 1000000} MB!`
      ]
    },
    roles: Object.values(USER_ROLES)
  }),
  methods: {
    async onSumbitRegister() {
      this.validate();
      if (!this.validation.valid) {
        return;
      }
      const body = this.register;
      body.language = this.getUserLanguage;
      const formData = new FormData();
      for (var key in body) {
        formData.append(key, body[key]);
      }

      try {
        const response = await this.registerUser(formData);
        this.setToast({
          message: response,
          type: TOAST_TYPE.SUCCESS
        });
        this.$router.push('/');
      } catch (error) {
        this.setToast({ message: error.message, type: TOAST_TYPE.ERROR });
        console.log('>>>', error, ' POST error  <<<');
      }
    },
    validate() {
      this.validation.valid = this.$refs.form.validate();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    ...mapActions({
      registerUser: 'user/registerUser',
      setToast: 'toast/setToast'
    }),
    uploadImage() {
      this.imageError = '';
      if (this.register.file) {
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.src = _URL.createObjectURL(this.register.file);
        img.onload = () => {
          if (img.width > AVATAR_IMG.WIDTH || img.height > AVATAR_IMG.HEIGHT) {
            this.imageError = `Picture must be max ${AVATAR_IMG.WIDTH}px x ${AVATAR_IMG.HEIGHT}px`;
          }
        };
      }
    }
  },
  computed: {
    passwordConfirmationRule() {
      return [
        () =>
          this.register.password === this.repeatPassword ||
          'Password must match',
        (v) => !!v || 'Required'
      ];
    },
    ...mapGetters({
      getRegisterLoader: 'user/getRegisterLoader',
      getUserLanguage: 'user/getUserLanguage'
    })
  }
};
</script>
