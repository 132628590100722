import Vue from 'vue';
import Vuex from 'vuex';
import instructionsList from './instructionsList';
import user from './user';
import instructionForm from './instructionForm';
import newBooking from './newBooking';
import toast from './toast';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    instructionsList,
    user,
    instructionForm,
    newBooking,
    toast
  }
});
