<template>
  <v-container>
    <v-btn to="/" class="px-2"
      ><v-icon>mdi-chevron-left</v-icon>Go to Home page</v-btn
    >

    <h1 class="display-1 font-weight-bold mb-5 text-center">
      Change Password
    </h1>

    <v-form ref="form" v-model="validation.valid" lazy-validation>
      <v-card class="mb-12 mx-auto pa-4" max-width="400" :disabled="isDisabled">
        <v-row justify="center">
          <v-col>
            <p class="ml-3 mb-1">New Password*</p>
            <v-text-field
              name="password"
              autocomplete="new-password"
              outlined
              type="password"
              background-color="white"
              v-model="newPassword"
              :rules="validation.passwordRules"
              placeholder=""
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col>
            <p class="ml-3 mb-1">Repeat Password*</p>
            <v-text-field
              name="password"
              autocomplete="new-password"
              outlined
              type="password"
              background-color="white"
              v-model="repeatPassword"
              :rules="passwordConfirmationRule"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-btn large class="orange-button" @click="onSumbitReset">
              <div class="white--text">Sumbit</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <Loader class="loader" v-if="getLoader" />
    </v-form>
  </v-container>
</template>

<script>
import { passwordRegEx } from '@/utils/regEx';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/Loader';
import { TOAST_TYPE } from '../utils/constants';
export default {
  name: 'Changepass',
  components: { Loader },
  data: () => ({
    repeatPassword: '',
    newPassword: '',
    email: '',
    token: '',
    snackbar: {
      show: false,
      color: '',
      message: ''
    },
    isDisabled: false,

    validation: {
      valid: false,
      passwordRules: [
        (v) => !!v || 'Required',
        (v) => {
          return passwordRegEx.test(v) || 'Password must be valid';
        }
      ],
      isTermsAgreeRules: [(v) => !!v || 'Required']
    }
  }),
  methods: {
    ...mapActions({
      changePassword: 'user/setNewPassword',
      setToast: 'toast/setToast'
    }),

    async onSumbitReset() {
      this.validate();
      if (!this.validation.valid) {
        return;
      }
      const body = {
        payload: {
          confirmpassword: this.repeatPassword,
          email: this.email,
          password: this.newPassword
        },
        token: this.token
      };
      this.isDisabled = true;
      try {
        const response = await this.changePassword(body);
        console.log(response);
        this.setToast({ message: response, type: TOAST_TYPE.SUCCESS });
        setTimeout(() => this.$router.push('/'), 1000);
      } catch (error) {
        this.isDisabled = false;
        this.setToast({
          message: 'FailedSaveNewPassword',
          type: TOAST_TYPE.ERROR
        });
        console.log('>>>', error, ' POST error  <<<');
      }
    },

    validate() {
      this.validation.valid = this.$refs.form.validate();
    }
  },
  computed: {
    ...mapGetters('user', ['getLoader']),
    passwordConfirmationRule() {
      return [
        () => this.newPassword === this.repeatPassword || 'Password must match',
        (v) => !!v || 'Required'
      ];
    }
  },
  mounted: function() {
    this.email = this.$route.query.email;
    this.token = this.$route.query.token;
  }
};
</script>

<style scoped>
form {
  position: relative;
}
.orange-button {
  width: 100%;
}
.v-card {
  border: solid 1px rgb(75, 72, 72);
}
.loader {
  position: absolute;
  margin: auto;
  top: 33%;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
