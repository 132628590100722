import { api, API } from '@/api';
import { instructionFormResponseBuilder } from '../components/instructionForm/utils';
export default {
  namespaced: true,
  state: {
    loading: false,
    loadingHSCodeList: false,
    instructionForm: {}
  },
  getters: {
    getLoadingHSCodeList(state) {
      return state.loadingHSCodeList;
    },
    getInstructionData(state) {
      return state.instructionForm;
    },
    getInstructionDataLoading(state) {
      return state.loading;
    }
  },
  mutations: {
    LOAD_FORM(state, payload) {
      state.loading = payload;
    },
    LOAD_HSCODE_LIST(state, payload) {
      state.loadingHSCodeList = payload;
    },
    SET_INSTRUCTION_FORM(state, payload) {
      state.instructionForm = { ...state.instructionForm, ...payload };
    },
    CLEAR_INSTRUCTION_FORM(state) {
      state.instructionForm = {};
    }
  },
  actions: {
    async getInstructionForm({ commit }, payload) {
      commit('LOAD_FORM', true);
      try {
        const response = await api.get(`${API.SINGLE_FORM}/${payload}`);
        const instruction = instructionFormResponseBuilder(response.data);
        commit('SET_INSTRUCTION_FORM', instruction);

        return response.data;
      } catch (error) {
        console.log('>>>', error, ' GET error  <<<');
        throw new Error(error.response ? error.response.data.message : error);
      } finally {
        commit('LOAD_FORM', false);
      }
    },
    async saveInstructionForm({ commit }, payload) {
      commit('LOAD_FORM', true);
      try {
        const response = await api.post(`${API.USER_FORM}/single`, payload);
        return response.data;
      } catch (error) {
        console.log('>>>', error, ' POST error  <<<');
        throw new Error(error);
      } finally {
        commit('LOAD_FORM', false);
      }
    },
    async getHSCodeList({ commit }, payload) {
      commit('LOAD_HSCODE_LIST', true);
      const prefix = isNaN(+payload) ? 'description' : 'id';
      try {
        const response = await api.get(
          `${API.SINGLE_COMMODITY_CODE}/by-${prefix}/${payload}`
        );
        commit('LOAD_HSCODE_LIST', false);
        return response.data.payload;
      } catch (error) {
        commit('LOAD_HSCODE_LIST', false);
        console.log('>>>', error, ' GET error  <<<');
        throw new Error(error);
      }
    },
    setInstructionForm({ commit }, payload) {
      commit('SET_INSTRUCTION_FORM', payload);
    },
    clearInstructionForm({ commit }) {
      commit('CLEAR_INSTRUCTION_FORM');
    }
  }
};
