import axios from 'axios';

export const API = {
  URL: process.env.VUE_APP_BACKEND_HOST,
  FORM: '/form',
  SINGLE_FORM: '/form/single',
  USER_LOGIN: '/user/login',
  USER_LOGOUT: '/user/current/logout',
  USER_REGISTER: '/user/register',
  USER_RESET: '/user/reset',
  USER_CHANGEPASS: '/user/changepass',
  USER_FORM: '/user/current/form',
  PORT: '/port',
  AVAILABLE_PORTS: '/port/available/all',
  CONTAINER: '/container',
  COMMODITY_CODE: '/commodity-code',
  SINGLE_COMMODITY_CODE: '/commodity-code/single',
  TRANSLATE: '/language/translate'
};

export const api = axios.create({
  baseURL: API.URL + '/api/v1',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
});
