import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import interceptors from './api/interceptors';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';

Vue.use(VueToast, { position: 'top-right' });
Vue.config.productionTip = false;

interceptors();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
