<template>
  <v-container class="custom-border">
    <v-card class="rounded-0 elevation-0">
      <div v-if="!isForgotPass">
        <h1 class="headline font-weight-bold mb-6">
          Log in to your account
        </h1>
        <v-form ref="form">
          <v-row class="text-right">
            <v-col cols="12">
              <v-text-field
                type="email"
                label="E-Mail / Userid"
                name="email"
                v-model="auth.email"
              ></v-text-field>

              <v-text-field
                type="password"
                label="Password"
                name="password"
                v-model="auth.password"
              ></v-text-field>
              <p class="red--text text-center" v-show="isAuthError">
                The user-id or password you supplied was incorrect. <br />
                Please try again. <br />
                If you received an "organization" like "EXT" or "HLAG", the user
                id needs to be extended to: "ext\userid" or "hlag\userid".
              </p>
              <v-btn color="#f6662d" @click="onSumbitLogin">
                <div class="white--text">Log In</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div v-else>
        <h1 class="headline font-weight-bold mb-6">
          Recover access to account
        </h1>
        <v-form ref="form">
          <v-row class="text-right">
            <v-col cols="12">
              <v-text-field
                type="email"
                label="E-Mail / Userid"
                name="email"
                @focus="refreshLabel"
                v-model="auth.email"
              ></v-text-field>
              <div class="messageText">
                <Loader v-if="getLoader" />
                <p
                  v-else
                  class="responseMessage"
                  v-bind:class="responseMessageColor"
                >
                  {{ responseMessage }}
                </p>
              </div>

              <v-btn color="#f6662d" @click="resetPassHandler">
                <div class="white--text">Reset password</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>

    <v-card class="rounded-0 elevation-0 pt-5 pb-5">
      <div>
        <div @click="forgotPassHandler">
          <p class="link" v-if="!isForgotPass">
            Forgot your Password?
          </p>
          <p class="link" v-else><span>&#x21E6;</span> Back</p>
        </div>
        <div class="link" @click="registerHandler" v-if="showRegisterLink">
          <div class="orange-text">Not yet registered?</div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/Loader';
import { TOAST_TYPE } from '../utils/constants';
export default {
  name: 'Login',
  components: { Loader },
  data: () => ({
    auth: {
      email: '',
      password: ''
    },
    isAuthError: false,
    isForgotPass: false,
    responseMessage: '',
    responseMessageColor: ''
  }),
  computed: {
    ...mapGetters('user', ['getResetResult', 'getLoader']),
    showRegisterLink() {
      return this.$route.path !== '/register';
    }
  },

  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword',
      logIn: 'user/logIn',
      setToast: 'toast/setToast'
    }),

    async onSumbitLogin() {
      const body = { email: this.auth.email, password: this.auth.password };
      try {
        await this.logIn(body);
        this.resetForm();
        this.$emit('dialog-close');
        this.$router.push('/allInstructions');
      } catch (error) {
        console.log('>>>', error, ' POST error  <<<');
        this.setToast({ message: error.message, type: TOAST_TYPE.ERROR });
        this.auth.email = '';
        this.auth.password = '';
        this.isAuthError = true;
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },

    forgotPassHandler() {
      this.isForgotPass = !this.isForgotPass;
      this.refreshLabel();
    },

    refreshLabel() {
      this.responseMessage = 'Enter your email';
      this.responseMessageColor = '';
    },
    async resetPassHandler() {
      const body = { email: this.auth.email };
      try {
        await this.resetPassword(body);
        this.setToast({
          message: this.getResetResult.message,
          type: TOAST_TYPE.SUCCESS
        });
      } catch (error) {
        console.log(this.getResetResult.message);
        this.setToast({
          message: this.getResetResult.message,
          type: TOAST_TYPE.ERROR
        });
      }
    },
    registerHandler() {
      this.$emit('dialog-close');
      this.$router.push('/register');
    }
  }
};
</script>

<style scoped>
.custom-border {
  border-bottom: 10px solid #f6662d !important;
}

.responseMessage {
  text-align: start;
}

.redMessage {
  color: red;
}

.greenMessage {
  color: green;
}

.messageText {
  height: 30px;
  color: grey;
}
</style>
