import { api, API } from '@/api';

export default {
  namespaced: true,
  state: {
    loading: false
  },
  mutations: {
    LOAD_INSTRUCTIONS_LIST(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
    async loadInstructionsList({ commit }) {
      commit('LOAD_INSTRUCTIONS_LIST', true);
      try {
        const instructionsList = await api.get(`${API.USER_FORM}/all`);

        return instructionsList.data;
      } catch (error) {
        console.log('>>>', error, ' GET error  <<<');
      } finally {
        commit('LOAD_INSTRUCTIONS_LIST', false);
      }
    }
  },
  getters: {
    getInstructionsListLoading(state) {
      return state.loading;
    }
  }
};
