import { api } from './api';
import store from '../store/index';

export default function interceptors() {
  api.interceptors.request.use(
    (request) => {
      const token = localStorage.getItem('token');
      const authUrls = [
        'form',
        'logout',
        'shipment',
        'port',
        'container',
        'commodity-code'
      ];
      if (token && authUrls.some((url) => request.url.includes(url))) {
        request.headers.Authorization = 'Bearer ' + token;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(undefined, (error) => {
    if (error.response.data?.code === 401) {
      store.dispatch('user/logOut');
    }
    return Promise.reject(error);
  });
}
