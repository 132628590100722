<template>
  <div class="home-screen">
    <v-container>
      <div class="text-center">
        <h1>Welcome to BlueQbit</h1>
      </div>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
};
</script>
