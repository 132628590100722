export const USER_ROLES = {
  CARRIER: 'Carrier',
  SHIPPER: 'Shipper'
};

export const LANGUAGES = {
  EN: 'en',
  ES: 'es'
};

export const TOAST_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success'
};

export const AVATAR_IMG = {
  WIDTH: 640,
  HEIGHT: 640,
  SIZE: 2000000
};
