<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-app-bar color="#f6662d" dense dark>
      <v-row justify="end" align="center">
        <v-col cols="2" v-show="getIsLoggedIn" class="header-buttons">
          <v-btn to="/new-booking" text class="ml-auto">
            NEW BOOKING
          </v-btn>
          <v-btn to="/" text @click="logOutHandler">
            LOGOUT
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" v-show="!getIsLoggedIn" class="header-buttons">
          <v-btn to="/register" text class="ml-auto">
            Register
          </v-btn>

          <v-dialog class="pa-0" v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                Login
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </template>
            <v-card>
              <Login v-on:dialog-close="dialog = false" />
            </v-card>
          </v-dialog>
        </v-col>

        <v-col cols="1" class="pa-0">
          <v-select
            class="select-language"
            :items="languages"
            :value="this.getUserLanguage"
            dense
            outlined
            hide-details
            @input="selectLanguage($event)"
          ></v-select>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
import { mapGetters, mapActions } from 'vuex';
import { LANGUAGES } from '../../utils/constants';
import { TOAST_TYPE } from '../../utils/constants';
export default {
  name: 'Header',
  components: { Login },

  data() {
    return {
      dialog: false,
      languages: Object.values(LANGUAGES)
    };
  },
  computed: {
    ...mapGetters({
      getIsLoggedIn: 'user/getIsLoggedIn',
      getUserLanguage: 'user/getUserLanguage'
    })
  },
  methods: {
    ...mapActions({
      logOut: 'user/logOut',
      getLanguage: 'user/getLanguage',
      setToast: 'toast/setToast'
    }),

    logOutHandler() {
      this.logOut();
    },
    async selectLanguage(event) {
      try {
        await this.getLanguage(event);
      } catch (error) {
        this.setToast({
          type: TOAST_TYPE.ERROR
        });
        console.log('>>>', error, ' GET error  <<<');
      }
    }
  },
  mounted() {
    const currentLang =
      JSON.parse(localStorage.getItem('user'))?.language || LANGUAGES.EN;
    this.selectLanguage(currentLang);
  }
};
</script>
<style scoped>
@import './Header.module.css';
</style>
