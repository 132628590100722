<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
    //
  },

  data: () => ({
    //
  }),

  mounted() {
    console.log(process.env.VUE_APP_BACKEND_HOST);
  }
};
</script>
<style>
@import './main.css';
</style>
